import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { Wrapper, Divider } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import { screenMax } from '@farewill/ui/helpers/responsive'
import { PartnerCustomMessage } from 'components/PartnerCustomMessage'
import WrapperWithEdge from 'components/WrapperWithEdge'
import TrustpilotReviews from 'components/TrustpilotWidget/TrustpilotReviews'
import Pricing from 'views/wills/components/Pricing'
import FrequentlyAskedQuestions from 'components/FrequentlyAskedQuestions'
import Guides from 'components/Guides'
import { PRODUCTS } from 'lib/products/constants'
import LinkButton from 'components/LinkButton'
import AwardBanner from 'components/AwardBanner'
import Charities from 'views/wills/components/Charities'
import UpdateWill from 'views/wills/components/UpdateWill'
import { WILLS_SIGN_UP_PATH } from 'config'

import Hero from '../Hero'
import Support from './Support'
import Steps from './Steps'
import Privacy from './Privacy'
import TelephoneSection from './TelephoneSection'
import Questions from './Questions'
import GetStarted from './GetStarted'

import { GUIDES_COPY } from './constants'

const StyledPartnerCustomMessageWrapper = styled(Wrapper)`
  ${screenMax.s`
 display: none;
`}
`

type OverviewProps = {
  heroContent?: {
    title?: string | null
    subtitle?: string | null
    ctaText?: string | null
  }
  isPartnerCampaignPage?: boolean
  telephoneWillsEnabled?: boolean
  hidePricing?: boolean
  partnerCustomMessage: string | null
  partnerDisplayName: string
}

const Overview = ({
  heroContent = {},
  isPartnerCampaignPage = false,
  telephoneWillsEnabled = false,
  hidePricing = false,
  partnerCustomMessage,
  partnerDisplayName,
}: OverviewProps): React.ReactElement => {
  const { faqSection } = useStaticQuery(graphql`
    query {
      faqSection: contentfulFaqSection(
        contentful_id: { eq: "7H9C9MQlHNJy6ww5C7Caso" }
      ) {
        ...FaqSectionFields
      }
    }
  `)

  // If telephoneWillsEnabled are set to false, we want to remove
  // "How much does it cost to make a will over the phone?" and
  // "Can I make a will over the phone instead?" questions from FAQs
  if (!telephoneWillsEnabled) {
    const questionIds = {
      howMuchItCosts: 'de42e3d0-5c1f-592f-b8f2-9894ca8ac979',
      overThePhoneInstead: '1c4e5f21-0dc7-5461-8f7a-e92e66e59390',
    }
    faqSection.questions = faqSection.questions.filter(
      ({ id }: { id: string }) =>
        ![questionIds.howMuchItCosts, questionIds.overThePhoneInstead].includes(
          id
        )
    )
  }

  return (
    <>
      <Wrapper background={COLOR.WHITE}>
        <Wrapper container>
          <Hero
            title={heroContent.title || undefined}
            subtitle={heroContent.subtitle || undefined}
            ctaText={heroContent.ctaText || undefined}
            isPartnerCampaignPage={isPartnerCampaignPage}
            preselectedService="online"
            telephoneWillsEnabled={telephoneWillsEnabled}
            partnerCustomMessage={partnerCustomMessage}
            partnerDisplayName={partnerDisplayName}
          />
        </Wrapper>

        {partnerCustomMessage ? (
          <StyledPartnerCustomMessageWrapper
            container
            containerVerticalPadding={0}
          >
            <PartnerCustomMessage
              partnerCustomMessage={partnerCustomMessage}
              partnerDisplayName={partnerDisplayName}
            />
          </StyledPartnerCustomMessageWrapper>
        ) : (
          <Divider container />
        )}

        <Wrapper container containerPaddingBottom="XL">
          <TrustpilotReviews showTitle />
        </Wrapper>

        <Divider />
        <WrapperWithEdge edgeBackgroundColor={COLOR.WHITE} direction="down">
          <AwardBanner />
        </WrapperWithEdge>

        <Wrapper background={COLOR.WHITE} container>
          <Support telephoneWillsEnabled={telephoneWillsEnabled} />
        </Wrapper>
      </Wrapper>
      <Divider />

      <Steps />

      {!hidePricing && (
        <Wrapper container containerPaddingTop={0} containerPaddingBottom={0}>
          <Pricing />
        </Wrapper>
      )}

      <Wrapper
        container
        containerPaddingTop="M"
        containerPaddingBottom="XL"
        centered
      >
        <LinkButton
          productLink
          to={WILLS_SIGN_UP_PATH}
          preselectedService="online"
          partnerCampaignReferral={isPartnerCampaignPage}
          telephoneWillsEnabled={telephoneWillsEnabled}
          data-track-event="click"
          data-track-element="cta"
          data-track-slice="3-easy-steps"
        >
          Let’s get started
        </LinkButton>
      </Wrapper>

      <Divider container />

      {hidePricing && (
        <>
          <UpdateWill illustration="illustrations/cropped/blob-with-laptop-and-cat" />
          <Divider container />
        </>
      )}

      <Privacy />

      <Divider container />

      {telephoneWillsEnabled && (
        <>
          <TelephoneSection />
          <Divider />
        </>
      )}

      <Wrapper background={COLOR.WHITE}>
        <Questions />
        <Divider container />
        <Wrapper container containerPaddingBottom={0}>
          <FrequentlyAskedQuestions
            title={faqSection.title}
            questions={faqSection.questions}
            relatedGuide={faqSection.relatedGuide}
            selectedProduct={PRODUCTS.WILLS}
            telephoneWillsEnabled={telephoneWillsEnabled}
            image="illustrations/checks-passed"
            imageBackground
            imageDecorativeSquiggle
          />
        </Wrapper>

        <Guides
          articles={GUIDES_COPY.articles}
          title={GUIDES_COPY.title}
          readMore={GUIDES_COPY.readMore}
          freeGuide={GUIDES_COPY.freeGuide}
        />

        <Wrapper container containerPaddingTop={0}>
          <Charities />
        </Wrapper>
      </Wrapper>

      <GetStarted
        partnerCampaignReferral={isPartnerCampaignPage}
        telephoneWillsEnabled={telephoneWillsEnabled}
      />
    </>
  )
}

export default Overview
